.symbol.symbol-circle > div {
    background-color: #8993A4;
    border-radius: 50%;

    .circle-inner {
      color: white;
      display: table-cell;
      vertical-align: middle; 
      text-align: center;
      text-decoration: none;
      margin: 20px;
      font-weight: bolder;
      height: 25px;
      width: 25px;
    }
}