#custom_columns_list {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  position: absolute;
  z-index: 99;
  top: 25px;
  right: 0;
  width: 300px;
}
.custom_columns_list_input {
  padding-right: 2.5rem;
  position: relative;
}
.custom_columns_list_input_icon_wrapper {
  position: absolute;
  top: 10px;
  right: 0.5rem;
  transform: translateY(-20%);
}
.custom_columns_list_container {
  height: 225px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
