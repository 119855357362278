.modal.open {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  padding: 20px;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* width: 30% !important; */
  position: absolute !important;
}
