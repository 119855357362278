
  .member-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .member-token {
    background-color: #f1f1f1;
    color: #333;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 4px;
    display: flex;
    align-items: center;
  }
  
  .remove-icon {
    margin-left: 4px;
    cursor: pointer;
  }

  .profile-circle {
    display: inline-block;
      border-radius: 50%;
      background-color: #8993A4;
      overflow: hidden;

      .profile-circle-inner {
        color: white;
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        height: 25px;
        width: 25px;  
        background-size: cover;
      }
  }
  
 