.profile-container {
    .project-name > h1 { 
        color: rgb(0, 158, 246);
        font-weight: bolder;
    }

    section {
        padding: 10px 0 10px 0;
        
        label {
            font-weight: bold;
        }
         &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: black; // Change color as needed
            margin-top: 10px; // Adjust spacing after section
        }
        &:last-child::after {
            content: none; // Remove the line after the last section
        }
    }
}


.k-animation-container  {
    // width: 100%;
}
