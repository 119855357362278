.gallery-dialog {
    .gallery-image {
        object-fit: contain; 
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

.image-gallery {
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: 400px;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}