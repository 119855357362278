.k-animation-container.k-animation-container-relative {
  width: 100%;
}
#project_assets {
  background-color: #fff;
  height: auto;
  padding: 10px;
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  width: 100%;
  min-height: 500px;
}
.project_assets_spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.project_assets_input {
  min-width: 400px;
  min-height: 35px;
  border-radius: 17.5px;
  border: 1px solid #e5e4e2;
  padding-left: 30px;
  outline: none;
  font-style: italic;
}
.project_assets_input_icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
