.k-window-title {
  font-weight: bold;
}

/* TOASTR */
#toast-container {
  margin-top: 1rem;
}

#toast-container.toast-top-full-width > .toast {
  max-width: 570px;
}

#toast-container > .toast-success {
  background-color: #51a351;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-success:hover {
  background-color: #51a351;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
#toast-container > .toast-error {
  background-color: #bd362f;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#toast-container > .toast-error:hover {
  background-color: #bd362f;
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
