#project-asset-list-table table.k-table.k-grid-header-table.k-table-md {
  width: 100% !important;
}
#project-asset-list-table table.k-table.k-grid-table.k-table-md {
  width: 100% !important;
}

.columns_list_button {
  background-color: #a71304;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.custom_header_cell_title {
  text-align: center;
  margin-bottom: 0px;
}
.custom_asset_name_first_part {
  font-weight: bold;
  color: #009ef6;
  margin-bottom: 0px;
}
.custom_asset_name_other_parts {
  margin-bottom: 0px;
}
.delete_asset_dialog {
  background-color: rgba(0, 0, 0, 0.45);
}
