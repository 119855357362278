.k-menu-group-md .k-menu-link {
  padding-block: 4px;
  padding-inline: 8px;
  padding-inline-end: 32px;
}

.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
  /* box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12); */
  box-shadow: unset !important;
}

.k-button-solid-base {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  width: 100px;
  height: 35px;
}

.k-menu-group .k-item > .k-link:active,
.k-menu-group .k-item > .k-link.k-active,
.k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
  color: white;
  background-color: #a71304 !important;
}
