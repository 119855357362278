
.unitrate-hdr {
    .content-fvt {
        background-color: #f0f0f0; /* Gray background color */
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 10px 10px 10px 10px;
        margin-bottom: 20px;
        height: 70px;
        .fvt-total {
            float: right;
        }
    }
}

