.asset-profile-container {
  .asset-name > h1 {
    color: rgb(0, 158, 246);
    font-weight: bolder;
  }

  section {
    padding: 10px 0 10px 0;

    label {
      font-weight: bold;
    }
  }
}
.carousel-controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
}

.carousel-control-prev,
.carousel-control-next {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  transition: background-color 0.3s;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

.carousel-control-icon {
  font-size: 24px;
  color: #000;
}
