#marketEvidenceForm div[aria-expanded='false'] i.fa-angle-up {
  display: none;
}
#marketEvidenceForm div[aria-expanded='true'] i.fa-angle-down {
  display: none;
}

#marketEvidenceForm .card-header {
  cursor: pointer;
}

div i.fa-angle-down,
div i.fa-angle-up {
  border-radius: 0px;
  box-shadow: none;
  transform: scale(2, 2);
  margin-right: 5px;
  color: #a71304;
}

.k-pager-md .k-pager-numbers-wrap .k-button {
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
}
.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg,
.k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 20px;
  height: 20px;
}
