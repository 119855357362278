.scrollable-component-container {
  height: 65vh;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}

/* Optional: style for better appearance */
.scrollable-component-container p {
  margin: 0 0 10px 0;
}

.scrollable-component-container.singlelinevaluationtab {
  height: calc(90vh - 318px);
}

.scrollable-component-container.singlelineinsurancetab {
  height: calc(88vh - 318px);
}

.scrollable-component-container.marketingapproachvaluationtab {
  height: calc(88vh - 200px);
}

.scrollable-component-container.assetinfotab {
  height: calc(88vh - 180px);
}

.scrollable-component-container.componentformtab {
  height: calc(93vh - 300px);
}

.scrollable-component-container.insurancetab {
  height: calc(93vh - 300px);
}

.scrollable-component-container.assetprofile {
  height: calc(88vh - 160px);
}
