//
// Modal
//
.scrollable-container {
    max-height: 400px; // Adjust the height as needed
    overflow-y: auto;
    padding-right: 15px; // Add padding to avoid scrollbar overlay on content
  
    // Customize scrollbar for better UI
    &::-webkit-scrollbar {
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

// Base
.modal {
    // Mobile header
    .modal-header {
        align-items: center;
        justify-content: space-between;
        @include border-top-radius($modal-content-border-radius);

        // Headings
        h1,h2,h3,h4,h5,h6 {
            margin-bottom: 0;
        }
    }

    // Mobile dialog
    .modal-dialog {
        outline: none !important;
    }
}

// Utilities
.modal-rounded {
    @if $enable-rounded {
        border-radius: $modal-content-border-radius !important;
    }
}