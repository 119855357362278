.k-window-title {
  font-weight: bold;
}

.controllerSelect.border-danger {
  border: 1px solid #f1416c !important;
}

.table-module {
  width: 100%;
  margin-bottom: 10px;

  thead > tr {
    th {
      width: calc(100% * 4 / 22);
      padding: 10px 0px 10px 0px;
    }
  }
  tbody {
    td {
      &.module-name {
        font-weight: bold;
        font-size: 15px;
      }
      vertical-align: top;
      text-align: left;
      padding: 5px 0 5px 0;
    }
  }
}
