.float-button {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
}

.report-sheet-card {
  border: 1px solid #ddd;
  padding: 15px;
  position: relative;
}

.report-sheet-card .remove {
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px 25px;
  padding: 4px 10px !important;
}

.report-sheet-card .reset {
  text-align: right;
  margin: 10px 25px;
  padding: 4px 10px !important;
}

.reference-date {
  width: 180px;
}

.reference-date-main {
  width: 100%;
}
