.project-name {
  color: rgb(0, 158, 246);
}
.k-grid tbody tr:hover {
  cursor: pointer;
}
i.fa {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0 0 2px #888;
  padding: 0.5em 0.6em;
}
